//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { collection, getDocs, getFirestore, orderBy, query, getDoc, doc } from 'firebase/firestore'
import { populateRecipe } from '../../populate'
import { IRecipe } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// Get Recipes List
//----------------------------------------------------------------------------------------------------------------------

export async function getRecipesList(): Promise<IRecipe[] | undefined> {
	const queries = []
	// 1. sort
	queries.push(orderBy('id', 'asc'))

	const col = collection(getFirestore(), 'recipes')
	const data = await getDocs(query(col, ...queries))
	return Promise.all(data.docs.map((doc) => populateRecipe({ doc })))
}

//----------------------------------------------------------------------------------------------------------------------
// Get Recipe By Id
//----------------------------------------------------------------------------------------------------------------------

export interface IGetRecipeByIdProps {
	id: string
}

export async function getRecipeById(props: IGetRecipeByIdProps): Promise<IRecipe | undefined> {
	const recipe = await getDoc(doc(getFirestore(), 'recipes', props.id))
	if ( recipe.exists() ) {
		return await populateRecipe({
			doc: recipe
		})
	} else {
		return undefined
	}
}
