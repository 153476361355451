//----------------------------------------------------------------------------------------------------------------------
// Define Error Action Types
//----------------------------------------------------------------------------------------------------------------------

export enum EErrorActionTypes {
	SET_AUTH_USER_IS_UNDEFINED = 'ERROR.SET_AUTH_USER_IS_UNDEFINED',
	SET_EMAIL_NOT_FOUND = 'ERROR.SET_EMAIL_NOT_FOUND',
	SET_INVALID_PASSWORD = 'ERROR.SET_INVALID_PASSWORD'
}

//----------------------------------------------------------------------------------------------------------------------
// Define Error Actions
//----------------------------------------------------------------------------------------------------------------------

export interface IActionErrorSetAuthUserIsUndefined {
	type: EErrorActionTypes.SET_AUTH_USER_IS_UNDEFINED,
	payload: boolean
}

export interface IActionErrorSetEmailNotFound {
	type: EErrorActionTypes.SET_EMAIL_NOT_FOUND,
	payload: boolean
}

export interface IActionErrorSetInvalidPassword {
	type: EErrorActionTypes.SET_INVALID_PASSWORD,
	payload: boolean
}

//----------------------------------------------------------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------------------------------------------------------

export type TActionError =
	IActionErrorSetAuthUserIsUndefined
	| IActionErrorSetEmailNotFound
	| IActionErrorSetInvalidPassword
