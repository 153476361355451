//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { ReactNode } from 'react'
import { Location } from 'react-router-dom'

//----------------------------------------------------------------------------------------------------------------------
// ILocation
//----------------------------------------------------------------------------------------------------------------------

export enum LOCATION_PROPS {
	FROM = 'from'
}

export interface ILocation {
	[LOCATION_PROPS.FROM]: Location
}

//----------------------------------------------------------------------------------------------------------------------
// IProps
//----------------------------------------------------------------------------------------------------------------------

export enum PROPS {
	CHILDREN = 'children',
	MATCH = 'match'
}

export interface IProps {
	[PROPS.CHILDREN]?: ReactNode,
}

//----------------------------------------------------------------------------------------------------------------------
// Function response
//----------------------------------------------------------------------------------------------------------------------

export enum EFunctionResponse {
	EMAIL_NOT_FOUND = 'auth/user-not-found',
	ERROR = 'error',
	INVALID_PASSWORD = 'auth/wrong-password',
	SUCCESS = 'success',
}

export type TFunctionResponse = Record<EFunctionResponse, string>

export interface IFunctionResponse {
	response: EFunctionResponse,
	text?: string
}

//----------------------------------------------------------------------------------------------------------------------
// Basic Action Callback
//----------------------------------------------------------------------------------------------------------------------

export interface IBasicActionCallback {
	callback: () => void
}

//----------------------------------------------------------------------------------------------------------------------
// Collections
//----------------------------------------------------------------------------------------------------------------------

export enum ECollectionTypes {
	RECIPES = 'recipes',
	RECENT_UPLOADED_FILES = 'recent_uploaded_files'
}

//----------------------------------------------------------------------------------------------------------------------
// Recipe Types
//----------------------------------------------------------------------------------------------------------------------

export enum ERecipeTypes {
	CONTENT = 'content',
	URL = 'url'
}

export enum EIngredientUnitTypes {
	GRAMS = 'g',
	KILOGRAM = 'kg',
	LITER = 'l',
	MILLILITER = 'ml',
	TABLESPOON = 'tablespoon',
	TEASPOON = 'teaspoon',
	GLASS = 'glass',
	PINCH = 'pinch',
	PIECES = 'pcs'
}

export interface IRecipeIngredient {
	name: string,
	value: number,
	unit: EIngredientUnitTypes
}

export interface IRecipeStep {
	image: IFile | undefined,
	text: string
}

export interface IRecipe {
	id: number,
	type: ERecipeTypes | undefined,
	created?: string,
	creator: string,
	title?: string,
	brief?: string,
	image?: IFile,
	portions?: number,
	ingredients?: IRecipeIngredient[],
	steps?: IRecipeStep[],
	notes?: string,
	url?: string,
}

//----------------------------------------------------------------------------------------------------------------------
// File Types
//----------------------------------------------------------------------------------------------------------------------

export enum EStorage {
	RECIPES = 'gs://donandron-recipes'
}

export interface IFile {
	id: string,
	url: string
}
