//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// API
//----------------------------------------------------------------------------------------------------------------------

export const firebaseConfig = {
	apiKey: 'AIzaSyC6QCQ2vDH1nVnvaa4_7hfReNwBhiB5h6o',
	authDomain: 'donandron-4d543.firebaseapp.com',
	projectId: 'donandron-4d543',
	storageBucket: 'donandron-4d543.appspot.com',
	messagingSenderId: '557530102475',
	appId: '1:557530102475:web:ecdd75a07fba1aba4df94e'
}
