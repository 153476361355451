//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React from 'react'
import { Outlet } from 'react-router-dom'

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

export const Layout: React.FC = () => {
	return (
		<main className="main container-sm pt-4 pb-4">
			<Outlet />
		</main>
	)
}
