//----------------------------------------------------------------------------------------------------------------------
// Declare String
//----------------------------------------------------------------------------------------------------------------------

declare global {
	interface Date {
		formatDate(): string,
	}
}

//----------------------------------------------------------------------------------------------------------------------
// Format Date
//----------------------------------------------------------------------------------------------------------------------

Date.prototype.formatDate = function() {
	return this.getDate().toString().padStart(2, '0')
		+ '.'
		+ (this.getMonth() + 1).toString().padStart(2, '0') + '.'
		+ this.getFullYear().toString()
}

//----------------------------------------------------------------------------------------------------------------------
// Empty Export
//----------------------------------------------------------------------------------------------------------------------

export { }

