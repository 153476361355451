//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { User } from 'firebase/auth'

//----------------------------------------------------------------------------------------------------------------------
// Define App Action Types
//----------------------------------------------------------------------------------------------------------------------

export enum EAppActionTypes {
	AUTH = 'APP.AUTH',
	SET_ME = 'APP.SET_ME'
}

//----------------------------------------------------------------------------------------------------------------------
// Define App Actions
//----------------------------------------------------------------------------------------------------------------------

export interface IActionAppSignIn {
	type: EAppActionTypes.AUTH,
	payload: true
}

export interface IActionAppLogout {
	type: EAppActionTypes.AUTH,
	payload: false
}

export interface IActionAppSetMe {
	type: EAppActionTypes.SET_ME,
	payload: User | undefined
}

//----------------------------------------------------------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------------------------------------------------------

export type TActionApp = IActionAppSignIn | IActionAppLogout | IActionAppSetMe
