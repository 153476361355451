//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { useContext } from 'react'
import { AuthContext, IAuthContextProps } from '../context'

//----------------------------------------------------------------------------------------------------------------------
// Use Auth Hook
//----------------------------------------------------------------------------------------------------------------------

export const useAuth = (): IAuthContextProps => {
	return useContext(AuthContext)
}
