//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React, { ReactNode } from 'react'
// import { useAction, useTypedSelector } from '../../hooks'

//----------------------------------------------------------------------------------------------------------------------
// Types
//----------------------------------------------------------------------------------------------------------------------

enum COMPONENT_REQUIRED_DATA_PROPS {
	CHILDREN = 'children'
}

interface IRequiredData {
	[COMPONENT_REQUIRED_DATA_PROPS.CHILDREN]: ReactNode
}

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

export const RequiredData: React.FC<IRequiredData> = (props) => {
	const { children } = props

	/* useEffect(() => {
		if ( !usersList.length ) getUsersListByQuery({})
	}, []) */


	return <>{children}</>
}
