//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { AuthProvider } from './context'
import store from './store'
import App from './App'
import { firebaseConfig } from './config'
import './prototype'

import 'bootstrap/dist/css/bootstrap.min.css'

//----------------------------------------------------------------------------------------------------------------------
// Firebase Initialisation
//----------------------------------------------------------------------------------------------------------------------

initializeApp(firebaseConfig)

//----------------------------------------------------------------------------------------------------------------------
// Set Persistence and Auth
//----------------------------------------------------------------------------------------------------------------------

setPersistence(getAuth(), browserLocalPersistence)

//----------------------------------------------------------------------------------------------------------------------
// Root Render
//----------------------------------------------------------------------------------------------------------------------

const rootContainer = document.getElementById('root')
const root = createRoot(rootContainer as Element)
root.render(<Provider store={ store }>
	<BrowserRouter>
		<AuthProvider>
			<Routes>
				<Route path="/*" element={ <App /> } />
			</Routes>
		</AuthProvider>
	</BrowserRouter>
</Provider>)
