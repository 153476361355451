//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React, { createContext, memo } from 'react'
import { IProps } from '../types'
import { useAction, useTypedSelector } from '../hooks'
import { User } from 'firebase/auth'

//----------------------------------------------------------------------------------------------------------------------
// Types
//----------------------------------------------------------------------------------------------------------------------

export interface IAuthContextProps {
	authorized: boolean | undefined,
	authenticate?: (user: User) => void
}

//----------------------------------------------------------------------------------------------------------------------
// Auth Provider
//----------------------------------------------------------------------------------------------------------------------

export const AuthProvider: React.FC<IProps> = memo(({ children }) => {
  const { authorized } = useTypedSelector((state) => state.app)
  const { auth } = useAction()

	function authenticate(user: User) {
    auth(user)
  }

  return (
    <AuthContext.Provider value={ { authorized, authenticate } }>
      { children }
    </AuthContext.Provider>
  )
})

//----------------------------------------------------------------------------------------------------------------------
// Auth Context
//----------------------------------------------------------------------------------------------------------------------

export const AuthContext = createContext<IAuthContextProps>({ authorized: false })
