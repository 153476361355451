//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { QueryDocumentSnapshot } from 'firebase/firestore'
import { IRecipe } from '../types'

//----------------------------------------------------------------------------------------------------------------------
// Types
//----------------------------------------------------------------------------------------------------------------------

interface IPopulateRecipeProps {
	doc: QueryDocumentSnapshot
}

//----------------------------------------------------------------------------------------------------------------------
// Populate Recipe
//----------------------------------------------------------------------------------------------------------------------

export async function populateRecipe(props: IPopulateRecipeProps): Promise<IRecipe> {
	const { doc } = props

	const recipe = doc.data()
	return {
		id: recipe.id,
		type: recipe.type || undefined,
		title: recipe.title || undefined,
		brief: recipe.brief || undefined,
		created: recipe.created ? new Date(recipe.created) : undefined,
		creator: recipe.creator,
		image: recipe.image || undefined,
		portions: recipe.portions || undefined,
		ingredients: recipe.ingredients || undefined,
		steps: recipe.steps || undefined,
		notes: recipe.notes || undefined,
		url: recipe.url || undefined
	} as IRecipe
}
