//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { IRecipe, IFunctionResponse } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// // Post Recipe Changes
//----------------------------------------------------------------------------------------------------------------------

export interface IPostRecipeChangesProps {
	recipe: IRecipe
}

export async function postRecipeChanges(props: IPostRecipeChangesProps): Promise<IFunctionResponse> {
	const response = await httpsCallable(getFunctions(getApp(), 'europe-central2'), 'saveRecipeChanges')(props.recipe)
	return response.data as IFunctionResponse
}

//----------------------------------------------------------------------------------------------------------------------
// // Post Clear Recipe
//----------------------------------------------------------------------------------------------------------------------

export interface IPostClearRecipeProps {
	id: number
}

export async function postClearRecipe(props: IPostClearRecipeProps): Promise<IFunctionResponse> {
	const response = await httpsCallable(getFunctions(getApp(), 'europe-central2'), 'clearRecipe')(props.id)
	return response.data as IFunctionResponse
}
