//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React, { memo, useCallback, useEffect, useState } from 'react'
import { Button, Input } from 'reactstrap'
import { uploadBytes, getStorage, ref, getDownloadURL } from 'firebase/storage'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getApp } from 'firebase/app'
import { EStorage, ECollectionTypes, IFile } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// Types
//----------------------------------------------------------------------------------------------------------------------

interface IAttachmentProps {
	value?: IFile,
	onDelete: () => void,
	onUpload: (value: IFile) => void,
	storage: EStorage,
}

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

export const Attachment: React.FC<IAttachmentProps> = memo((props) => {
	const { value, onDelete, onUpload, storage } = props

	const [ file, setFile ] = useState(value)
	const [ insertButtonActive, setInsertButtonActive ] = useState(false)

	useEffect(() => {
		setFile(value)
	}, [ value ])

	const handleUpload = useCallback((value: IFile) => {
		setFile(value)
		console.log('on upload')
		onUpload(value)
	}, [ onUpload, setFile ])

	const handleDelete = useCallback(async () => {
		setFile(undefined)
		onDelete()
	}, [ onUpload, setFile ])

	const handleAttachmentsChange = useCallback(async (value?: File) => {
		setInsertButtonActive(false)
		if ( value ) {
			await uploadFile(value, storage, handleUpload)
		} else {
			return
		}
	}, [])

	return (<div className="attachment-wrapper">
			{ file
				? <div className="position-relative">
					<img
						src={ file.url }
						alt={ file.id }
						style={ { maxWidth: '100%', height: 'auto', objectFit: 'cover', borderRadius: '5px' } }
					/>
					<Button
						close
						style={ { position: 'absolute', top: '10px', right: '10px', backgroundColor: 'white', padding: '8px' } }
						onClick={ handleDelete }
					/>
				</div>
				: <div className="position-relative">
					<Button block active={ insertButtonActive }>Выбрать файл</Button>
					<Input
						style={ { opacity: 0, position: 'absolute', top: 0 } }
						type="file"
						accept="image/*"
						multiple={ false }
						onClick={ () => setInsertButtonActive(true) }
						onChange={ (e) => handleAttachmentsChange(e.target?.files?.[0]) }
					/>
				</div>
			}
		</div>
	)
})

async function uploadFile(file: File, storage: EStorage, onUpload: (value: IFile) => void) {
	const now = new Date()
	const storageRef = getStorage(getApp(), storage)
	const imageId = Math.random().toString(16).slice(-8) + '-' + file.name.replace(/\s/g, '_')
	const imageRef = ref(storageRef, imageId)
	try {
		const result = await uploadBytes(imageRef, file, { contentType: file.type })
		if ( result.ref ) {
			const url = await getDownloadURL(result.ref)
			await setDoc(doc(getFirestore(), `${ ECollectionTypes.RECENT_UPLOADED_FILES }`, imageId), {
				user: getAuth().currentUser?.uid, created: now.toISOString(), url
			})
			onUpload({ url, id: imageId } as IFile)
		}
	} catch (e) {
		console.log(e)
	}
}
