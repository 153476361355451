//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React, { useEffect } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useAction, useAuth } from '../../hooks'
import { RequiredData } from '../required_data/RequiredData'

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

export const RequireAuth: React.FC = () => {
	const { authorized, authenticate } = useAuth()
	const { logout } = useAction()
	const location = useLocation()

	useEffect(() => {
		onAuthStateChanged(getAuth(), (user) => {
			if ( user && authenticate ) authenticate(user)
			else logout()
		})
	}, [ ])

	return (
		authorized
			? <RequiredData><Outlet /></RequiredData>
			: <Navigate to="/auth" state={ { from: location } } replace />
	)
}
