//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { Dispatch } from 'redux'
import { getAuth, signInWithEmailAndPassword, signOut, User } from 'firebase/auth'
import { EAppActionTypes, EErrorActionTypes, EFunctionResponse, TActionApp, TActionError } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// Auth
//----------------------------------------------------------------------------------------------------------------------

export const signIn = (email: string, password: string) => {
  return async (dispatch: Dispatch<TActionError>) => {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password)
    } catch (error) {
      switch ( error.code ) {
        case ( EFunctionResponse.EMAIL_NOT_FOUND ):
          dispatch({
            type: EErrorActionTypes.SET_EMAIL_NOT_FOUND,
            payload: true
          })
          dispatch({
            type: EErrorActionTypes.SET_INVALID_PASSWORD,
            payload: false
          })
          break
        case ( EFunctionResponse.INVALID_PASSWORD ):
          dispatch({
            type: EErrorActionTypes.SET_EMAIL_NOT_FOUND,
            payload: false
          })
          dispatch({
            type: EErrorActionTypes.SET_INVALID_PASSWORD,
            payload: true
          })
          break
        default:
          dispatch({
            type: EErrorActionTypes.SET_AUTH_USER_IS_UNDEFINED,
            payload: true
          })
      }
    }
    dispatch({
      type: EErrorActionTypes.SET_EMAIL_NOT_FOUND,
      payload: false
    })
    dispatch({
      type: EErrorActionTypes.SET_INVALID_PASSWORD,
      payload: false
    })
    dispatch({
      type: EErrorActionTypes.SET_AUTH_USER_IS_UNDEFINED,
      payload: false
    })
  }
}

export const logout = () => {
  return async (dispatch: Dispatch<TActionApp>): Promise<EFunctionResponse> => {
    await signOut(getAuth())
    dispatch({
      type: EAppActionTypes.AUTH,
      payload: false
    })
	  return EFunctionResponse.SUCCESS
  }
}

//----------------------------------------------------------------------------------------------------------------------
// Set Auth
//----------------------------------------------------------------------------------------------------------------------

export const auth = (user: User) => {
  return async (dispatch: Dispatch<TActionApp | TActionError>): Promise<EFunctionResponse> => {
    if ( user === null ) {
      dispatch({
        type: EAppActionTypes.AUTH,
        payload: false
      })
      dispatch({
        type: EErrorActionTypes.SET_AUTH_USER_IS_UNDEFINED,
        payload: true
      })
	    return EFunctionResponse.ERROR
    } else {
      dispatch({
        type: EAppActionTypes.SET_ME,
        payload: user
      })
      dispatch({
        type: EErrorActionTypes.SET_AUTH_USER_IS_UNDEFINED,
        payload: false
      })
      dispatch({
        type: EAppActionTypes.AUTH,
        payload: true
      })
	    return EFunctionResponse.SUCCESS
    }
  }
}
