//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { IRecipe } from '../default'

//----------------------------------------------------------------------------------------------------------------------
// Define Recipe State Types
//----------------------------------------------------------------------------------------------------------------------

export enum ERecipeStateTypes {
	CURRENT = 'current',
	CURRENT_LOADING = 'currentLoading',
	LIST = 'list',
	LIST_LOADING = 'listLoading'
}

//----------------------------------------------------------------------------------------------------------------------
// Define Recipe State Types
//----------------------------------------------------------------------------------------------------------------------

export interface IStateRecipe {
	[ERecipeStateTypes.CURRENT]?: IRecipe,
	[ERecipeStateTypes.CURRENT_LOADING]: boolean,
	[ERecipeStateTypes.LIST]?: IRecipe[],
	[ERecipeStateTypes.LIST_LOADING]: boolean,
}
