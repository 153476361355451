//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { combineReducers } from '@reduxjs/toolkit'
import { appReducer, initialAppState } from './appReducer'
import { errorReducer, initialErrorState } from './errorReducer'
import { initialRecipeState, recipeReducer } from './recipeReducer'

//----------------------------------------------------------------------------------------------------------------------
// Initialize Root Reducer
//----------------------------------------------------------------------------------------------------------------------

const rootReducer = combineReducers({
  app: appReducer,
  error: errorReducer,
	recipe: recipeReducer
})

export const preloadedState = {
  app: initialAppState,
  error: initialErrorState,
	recipe: initialRecipeState
}

//----------------------------------------------------------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------------------------------------------------------

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
