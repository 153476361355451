//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import rootReducer, { preloadedState } from './reducers'

//----------------------------------------------------------------------------------------------------------------------
// Constants
//----------------------------------------------------------------------------------------------------------------------

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => (
		getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(logger)
	),
	preloadedState,
	devTools: process.env.NODE_ENV !== 'production'
})

//----------------------------------------------------------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------------------------------------------------------

export default store
