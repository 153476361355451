//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { IRecipe } from '../default'

//----------------------------------------------------------------------------------------------------------------------
// Define Recipe Action Types
//----------------------------------------------------------------------------------------------------------------------

export enum ERecipeActionTypes {
	SET_CURRENT = 'RECIPE.SET_CURRENT',
	SET_CURRENT_LOADING = 'RECIPE.SET_CURRENT_LOADING',
	SET_LIST = 'RECIPE.SET_LIST',
	SET_LIST_LOADING = 'RECIPE.SET_LIST_LOADING',
	UPDATE_RECIPE_IN_LIST = 'RECIPE.UPDATE_RECIPE_IN_LIST'
}

//----------------------------------------------------------------------------------------------------------------------
// Define Recipe Actions
//----------------------------------------------------------------------------------------------------------------------

export interface IActionRecipeSetCurrent {
	type: ERecipeActionTypes.SET_CURRENT,
	payload?: IRecipe
}

export interface IActionRecipeSetCurrentLoading {
	type: ERecipeActionTypes.SET_CURRENT_LOADING,
	payload: boolean
}

export interface IActionRecipeSetList {
	type: ERecipeActionTypes.SET_LIST,
	payload?: IRecipe[]
}

export interface IActionRecipeSetListLoading {
	type: ERecipeActionTypes.SET_LIST_LOADING,
	payload: boolean
}

export interface IActionRecipeUpdateRecipeInList {
	type: ERecipeActionTypes.UPDATE_RECIPE_IN_LIST,
	payload: IRecipe
}

//----------------------------------------------------------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------------------------------------------------------

export type TActionRecipe =
	IActionRecipeSetCurrent
	| IActionRecipeSetCurrentLoading
	| IActionRecipeSetList
	| IActionRecipeSetListLoading
	| IActionRecipeUpdateRecipeInList
