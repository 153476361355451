//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import './index.css'

//----------------------------------------------------------------------------------------------------------------------
// Components
//----------------------------------------------------------------------------------------------------------------------

import { Layout, RequireAuth, LoadingCircle } from './components'

//----------------------------------------------------------------------------------------------------------------------
// Lazy Pages
//----------------------------------------------------------------------------------------------------------------------

const AuthPage = lazy(() => import('./pages/auth_page/AuthPage'))
const RecipePage = lazy(() => import('./pages/recipe_page/RecipePage'))
const RecipeEditPage = lazy(() => import('./pages/recipe_page/RecipeEditPage'))
const ListPage = lazy(() => import('./pages/list_page/ListPage'))

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

function App() {
	return (<>
		<Routes>
			<Route path="/" element={ <Layout /> }>
				{/* public routes */ }
				<Route path="auth" element={ <Suspense fallback={<LoadingCircle />}><AuthPage /></Suspense> } />
				<Route path="/:id" element={<Suspense fallback={<LoadingCircle />}><RecipePage /></Suspense> } />

				{/* protected routes */ }
				<Route element={ <RequireAuth /> }>
					<Route path="/:id/edit" element={<Suspense fallback={<LoadingCircle />}><RecipeEditPage /></Suspense> } />
					<Route path="/" element={<Suspense fallback={<LoadingCircle />}><ListPage /></Suspense> } />
				</Route>

				{/* catch all */ }
				<Route path="*" element={ <Navigate to={'/'} /> } />
			</Route>
		</Routes>
	</>)
}

//----------------------------------------------------------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------------------------------------------------------

export default App
