//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { User } from 'firebase/auth'

//----------------------------------------------------------------------------------------------------------------------
// Define App State Types
//----------------------------------------------------------------------------------------------------------------------

export enum EAppStateTypes {
	AUTHORIZED = 'authorized',
	ME = 'me'
}

//----------------------------------------------------------------------------------------------------------------------
// Define App State Types
//----------------------------------------------------------------------------------------------------------------------

export interface IStateApp {
	[EAppStateTypes.AUTHORIZED]: boolean | undefined,
	[EAppStateTypes.ME]?: User
}
