//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ActionCreators from '../store/action_creators'

//----------------------------------------------------------------------------------------------------------------------
// Use Action Hook
//----------------------------------------------------------------------------------------------------------------------

export const useAction = () => {
	const dispatch = useDispatch()
	return bindActionCreators(ActionCreators, dispatch)
}
