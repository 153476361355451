//----------------------------------------------------------------------------------------------------------------------
// Define Error State Types
//----------------------------------------------------------------------------------------------------------------------

export enum EErrorStateTypes {
	AUTH_USER_IS_UNDEFINED = 'authUserIsUndefined',
	EMAIL_NOT_FOUND = 'emailNotFound',
	INVALID_PASSWORD = 'invalidPassword'
}

//----------------------------------------------------------------------------------------------------------------------
// Define Error State Types
//----------------------------------------------------------------------------------------------------------------------

export interface IStateError {
	[EErrorStateTypes.AUTH_USER_IS_UNDEFINED]: boolean,
	[EErrorStateTypes.EMAIL_NOT_FOUND]: boolean,
	[EErrorStateTypes.INVALID_PASSWORD]: boolean
}
