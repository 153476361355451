//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import React from 'react'
import { Spinner } from 'reactstrap'

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

export const LoadingCircle: React.FC = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center position-absolute"
			style={{ width: '100vw', height: '100vh', top: 0, left: 0 }}
		>
			<Spinner color="primary"/>
		</div>
	)
}
