import React, { memo } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

//----------------------------------------------------------------------------------------------------------------------
// Types
//----------------------------------------------------------------------------------------------------------------------

interface IPaginationWrapperProps {
	currentPage: number,
	length: number,
	onPageChange: React.Dispatch<React.SetStateAction<number>>
}

//----------------------------------------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------------------------------------

export const PaginationWrapper: React.FC<IPaginationWrapperProps> = memo((props) => {
	const { currentPage, length, onPageChange } = props

	const pages = Array.from({ length }, (_, i) => i + 1)

	return (<div className="d-flex justify-content-center mt-5">
		<Pagination>
			<PaginationItem
				disabled={ currentPage === pages[0] }
				onClick={ () => {
					currentPage > pages[0]
						? onPageChange(pages[0])
						: void null
				} }
			>
				<PaginationLink
					first
				/>
			</PaginationItem>
			<PaginationItem
				disabled={ currentPage === pages[0] }
				onClick={ () => {
					currentPage > pages[0]
						? onPageChange((prev) => prev - 1)
						: void null
				} }
			>
				<PaginationLink
					previous
				/>
			</PaginationItem>
			{ pages.map((value) => (
				<PaginationItem
					key={`pagination-page-${value}`}
					active={ currentPage === value }
					onClick={ () => onPageChange(value) }
				>
					<PaginationLink>
						{ value.toString() }
					</PaginationLink>
				</PaginationItem>
			)) }
			<PaginationItem
				disabled={ currentPage === pages[pages.length - 1] }
				onClick={ () => {
					currentPage < pages[pages.length - 1]
						? onPageChange((prev) => prev + 1)
						: void null
				} }
			>
				<PaginationLink
					next
				/>
			</PaginationItem>
			<PaginationItem
				disabled={ currentPage === pages[pages.length - 1] }
				onClick={ () => {
					currentPage < pages[pages.length - 1]
						? onPageChange(pages[pages.length - 1])
						: void null
				} }
			>
				<PaginationLink
					last
				/>
			</PaginationItem>
		</Pagination>
	</div>)
})
