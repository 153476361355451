//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { EAppActionTypes, EAppStateTypes, IStateApp, TActionApp } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// Initialize State
//----------------------------------------------------------------------------------------------------------------------

export const initialAppState: IStateApp = {
  [EAppStateTypes.AUTHORIZED]: undefined,
  [EAppStateTypes.ME]: undefined
}

//----------------------------------------------------------------------------------------------------------------------
// Reducer
//----------------------------------------------------------------------------------------------------------------------

export const appReducer = (state = initialAppState, action: TActionApp): IStateApp => {
  switch ( action.type ) {
    case EAppActionTypes.AUTH:
      return { ...state, [EAppStateTypes.AUTHORIZED]: action.payload }
    case EAppActionTypes.SET_ME:
      return { ...state, [EAppStateTypes.ME]: action.payload }
    default:
      return state
  }
}
