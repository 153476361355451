//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { Dispatch } from 'redux'
import { EFunctionResponse, ERecipeActionTypes, IBasicActionCallback, IRecipe, TActionRecipe } from '../../types'
import { getRecipeById, getRecipesList, postClearRecipe, postRecipeChanges } from '../../api'

//----------------------------------------------------------------------------------------------------------------------
// Set Current Recipe
//----------------------------------------------------------------------------------------------------------------------

export const setCurrentRecipeFromList = (recipe: IRecipe) => {
  return async (dispatch: Dispatch<TActionRecipe>) => {
    dispatch({
      type: ERecipeActionTypes.SET_CURRENT_LOADING,
      payload: true
    })
    dispatch({
      type: ERecipeActionTypes.SET_CURRENT,
      payload: recipe
    })
    dispatch({
      type: ERecipeActionTypes.SET_CURRENT_LOADING,
      payload: false
    })
  }
}

//----------------------------------------------------------------------------------------------------------------------
// Fetch Recipes List
//----------------------------------------------------------------------------------------------------------------------

export const fetchRecipesList = () => {
  return async (dispatch: Dispatch<TActionRecipe>) => {
    const recipes = await getRecipesList()
    dispatch({
      type: ERecipeActionTypes.SET_LIST_LOADING,
      payload: true
    })
    dispatch({
      type: ERecipeActionTypes.SET_LIST,
      payload: recipes
    })
    dispatch({
      type: ERecipeActionTypes.SET_LIST_LOADING,
      payload: false
    })
  }
}

//----------------------------------------------------------------------------------------------------------------------
// Fetch Recipe By Id
//----------------------------------------------------------------------------------------------------------------------

export interface IFetchRecipeByIdProps {
  id: string
}

export const fetchRecipeById = (props: IFetchRecipeByIdProps) => {
  return async (dispatch: Dispatch<TActionRecipe>) => {
    const recipe = await getRecipeById(props)
    dispatch({
      type: ERecipeActionTypes.SET_CURRENT_LOADING,
      payload: true
    })
    dispatch({
      type: ERecipeActionTypes.SET_CURRENT,
      payload: recipe
    })
    dispatch({
      type: ERecipeActionTypes.SET_CURRENT_LOADING,
      payload: false
    })
  }
}

//----------------------------------------------------------------------------------------------------------------------
// Save Recipe Changes
//----------------------------------------------------------------------------------------------------------------------

export interface ISaveRecipeChangesProps extends IBasicActionCallback {
  payload: IRecipe
}

export const saveRecipeChanges = (props: ISaveRecipeChangesProps) => {
  const { payload, callback } = props
  return async (dispatch: Dispatch<TActionRecipe>) => {
    const response = await postRecipeChanges({ recipe: payload })
    if ( response.response === EFunctionResponse.SUCCESS ) {
      dispatch({
        type: ERecipeActionTypes.UPDATE_RECIPE_IN_LIST,
        payload
      })
      callback()
    } else {
      console.error('Couldn\'t save recipe changes')
      return
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------
// Clear Recipe
//----------------------------------------------------------------------------------------------------------------------

export interface IClearRecipeProps extends IBasicActionCallback {
  payload: IRecipe
}

export const clearRecipe = (props: IClearRecipeProps) => {
  const { payload, callback } = props
  return async (dispatch: Dispatch<TActionRecipe>) => {
    const response = await postClearRecipe({ id: payload.id })
    if ( response.response === EFunctionResponse.SUCCESS ) {
      dispatch({
        type: ERecipeActionTypes.UPDATE_RECIPE_IN_LIST,
        payload
      })
      callback()
    } else {
      console.error('Couldn\'t clear recipe')
      return
    }
  }
}
