//----------------------------------------------------------------------------------------------------------------------
// Declare String
//----------------------------------------------------------------------------------------------------------------------

declare global {
	interface String {
		addTargetRelToLinks(target: HTMLAnchorElement['target'], rel: HTMLAnchorElement['rel']): string,

		formatDate(): string,

		removeCharsFromString(chars: string[]): string,

		removeExtraSpaces(): string,

		removeExtraChars(char: string): string,

		stripHtml(): string,

		capitalizeFirstLetter(): string
	}
}

//----------------------------------------------------------------------------------------------------------------------
// Add Target Rel To Links
//----------------------------------------------------------------------------------------------------------------------

String.prototype.addTargetRelToLinks = function(target, rel) {
	const attrToAdd = `target="${ target }" rel="${ rel }"`
	const regexp = new RegExp('<(a)([^>]+)>', 'gi')
	return this.replace(regexp, `<$1 ${ attrToAdd }$2>`)
}

//----------------------------------------------------------------------------------------------------------------------
// Remove Chars From String
//----------------------------------------------------------------------------------------------------------------------

String.prototype.removeCharsFromString = function(chars) {
	let newString = this.toString()
	chars.forEach((char) => newString = newString.replaceAll(char, ''))
	newString.split('')
	return newString as string
}

//----------------------------------------------------------------------------------------------------------------------
// Remove Extra Spaces
//----------------------------------------------------------------------------------------------------------------------

String.prototype.removeExtraSpaces = function() {
	return this.replace(/\s+/g, ' ').trim()
}

//----------------------------------------------------------------------------------------------------------------------
// Remove Extra Chars
//----------------------------------------------------------------------------------------------------------------------

String.prototype.removeExtraChars = function(char) {
	const reg = `(\\${ char })\\1+`
	const regexp = new RegExp(reg, 'g')
	return this.replace(regexp, char)
}

//----------------------------------------------------------------------------------------------------------------------
// Strip HTML
//----------------------------------------------------------------------------------------------------------------------

String.prototype.stripHtml = function() {
	const tmp = document.createElement('DIV')
	tmp.innerHTML = this as string
	return tmp.textContent || tmp.innerText || ''
}

String.prototype.capitalizeFirstLetter = function() {
	return this.charAt(0).toUpperCase() + this.slice(1)
}

//----------------------------------------------------------------------------------------------------------------------
// Empty Export
//----------------------------------------------------------------------------------------------------------------------

export {}
