//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { ERecipeActionTypes, ERecipeStateTypes, IStateRecipe, TActionRecipe } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// Initialize State
//----------------------------------------------------------------------------------------------------------------------

export const initialRecipeState: IStateRecipe = {
  [ERecipeStateTypes.CURRENT]: undefined,
  [ERecipeStateTypes.CURRENT_LOADING]: true,
  [ERecipeStateTypes.LIST]: undefined,
  [ERecipeStateTypes.LIST_LOADING]: true
}

//----------------------------------------------------------------------------------------------------------------------
// Reducer
//----------------------------------------------------------------------------------------------------------------------

export const recipeReducer = (state = initialRecipeState, action: TActionRecipe): IStateRecipe => {
  switch ( action.type ) {
    case ERecipeActionTypes.SET_CURRENT:
      return { ...state, [ERecipeStateTypes.CURRENT]: action.payload }
    case ERecipeActionTypes.SET_CURRENT_LOADING:
      return { ...state, [ERecipeStateTypes.CURRENT_LOADING]: action.payload }
    case ERecipeActionTypes.SET_LIST:
      return { ...state, [ERecipeStateTypes.LIST]: action.payload }
    case ERecipeActionTypes.SET_LIST_LOADING:
      return { ...state, [ERecipeStateTypes.LIST_LOADING]: action.payload }
    case ERecipeActionTypes.UPDATE_RECIPE_IN_LIST:
      console.log('try to update')
      if ( state.list ) {
        let recipesList = state.list
        recipesList = recipesList.map((recipe) => recipe.id !== action.payload.id ? recipe : action.payload)
        console.log(action.payload)
        console.log(recipesList)
        return { ...state, [ERecipeStateTypes.LIST]: recipesList }
      } else {
        return state
      }
    default:
      return state
  }
}
