//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { EErrorActionTypes, EErrorStateTypes, IStateError, TActionError } from '../../types'

//----------------------------------------------------------------------------------------------------------------------
// Initialize State
//----------------------------------------------------------------------------------------------------------------------

export const initialErrorState: IStateError = {
  [EErrorStateTypes.AUTH_USER_IS_UNDEFINED]: false,
  [EErrorStateTypes.EMAIL_NOT_FOUND]: false,
  [EErrorStateTypes.INVALID_PASSWORD]: false
}

//----------------------------------------------------------------------------------------------------------------------
// Reducer
//----------------------------------------------------------------------------------------------------------------------

export const errorReducer = (state = initialErrorState, action: TActionError): IStateError => {
  switch ( action.type ) {
    case EErrorActionTypes.SET_AUTH_USER_IS_UNDEFINED:
      return { ...state, [EErrorStateTypes.AUTH_USER_IS_UNDEFINED]: action.payload }
    case EErrorActionTypes.SET_EMAIL_NOT_FOUND:
      return { ...state, [EErrorStateTypes.EMAIL_NOT_FOUND]: action.payload }
    case EErrorActionTypes.SET_INVALID_PASSWORD:
      return { ...state, [EErrorStateTypes.INVALID_PASSWORD]: action.payload }
    default:
      return state
  }
}
