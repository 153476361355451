//----------------------------------------------------------------------------------------------------------------------
// Imports
//----------------------------------------------------------------------------------------------------------------------

import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { RootState } from '../store/reducers'

//----------------------------------------------------------------------------------------------------------------------
// Use Typed Selector Hook
//----------------------------------------------------------------------------------------------------------------------

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
